<template>
    <div>
        <div class="filter-panel">
            <input
                type="text"
                v-model="queryParams.name"
                class="cs-input"
                style="margin: 0 10px 15px 0; border-radius: 4px;"
                placeholder="请输入权限组名称"
            />
            <button class="btn btn-primary ml-4" @click="getRoleList">
                查询
            </button>
        </div>

        <div class="result-panel">
            <div class="result-panel-header text-right">
                <button
                    class="btn btn-primary"
                    @click="openAddOrEditRoleModal()"
                >
                    <svg 
                        class="icon" 
                        aria-hidden="true"
                    >
                        <use xlink:href="#icon-menujiahao"></use>
                    </svg>添加权限组
                </button>
            </div>
            <div class="row">
                <ul class="col-sm-12">
                    <li
                        class="list-group-item"
                        style="font-size: 14px; padding: 10px 30px;line-height: 36px; display: flex; justify-content: space-between;"
                        v-for="role in roleList"
                        :key="role.id"
                    >   
                        <div style="width: 150px; text-align: center; fonr-size: 14px;">
                            {{ role.name }}
                        </div>
                        <div class="pull-right">
                            <div class="btn-group">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu">
                                    <li
                                        @click="openRoleBindingResourceDialog(role.id)"
                                    >
                                        <a
                                            style="width: 100%;"
                                        >设置权限</a>
                                    </li>
                                    <li @click="openAddOrEditRoleModal(role)">
                                        <a
                                            style="width: 100%;"
                                        >修改</a>
                                    </li>
                                    <li @click="deleteRoleModalInfo.visible = true;
                                        deleteRoleModalInfo.roleId = role.id;">
                                        <a
                                            style="width: 100%;"
                                        >删除</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 添加接口权限 -->
        <CSDialog :dialog-visible="roleAccessModalVisible" dialog-title="添加接口权限"
          @onConfirm="setupRoleResources" @onClose="roleAccessModalVisible = false"
        >
          <template v-slot:dialog-content>
            <div style="padding: 30px; font-size: 24px;">
              <div class="row">
                <div class="col-sm-6">
                  <h4 style="font-size: 24px;">可添加</h4>
                  <div style="max-height: 420px; overflow-y: auto">
                    <el-tree
                        :data="resourceList"
                        node-key="id"
                        :default-expanded-keys="checkedExpandedKey"
                        :default-checked-keys="checkedReousrceKey"
                        :props="{
                                label: 'name',
                                children: 'resources',
                            }"
                    >
                            <span
                                class="custom-tree-node"
                                slot-scope="{ node, data }"
                            >
                                <span>{{ node.label }}</span>
                                <template
                                    v-if="node.level > 1 || !data.menuItem"
                                >
                                    <span>
                                        <i
                                            :class="[
                                                'el-icon-circle-plus-outline',
                                                {
                                                    gray: checkedReousrceKey.includes(
                                                        data.id
                                                    ),
                                                },
                                            ]"
                                            @click="appendResource(node, data)"
                                            style="line-height: 36px;"
                                        >
                                        </i>
                                    </span>
                                </template>
                            </span>
                    </el-tree>
                    <!-- @check-change="changeCheckedResource" -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <h4 style="font-size: 24px;">已添加</h4>
                  <div>
                    <div style="max-height: 420px; overflow-y: auto">
                      <el-tree
                          :data="roleBindedResourceList"
                          :props="{
                                    label: 'name',
                                    children: 'resources',
                                }"
                      >
                                <span
                                    class="custom-tree-node"
                                    slot-scope="{ node, data }"
                                >
                                    <span>{{ node.label }}</span>
                                    <template
                                        v-if="node.level > 1 || !data.menuItem"
                                    >
                                        <span>
                                            <i
                                                @click="
                                                    removeResource(node, data)
                                                "
                                                class="el-icon-remove-outline"
                                            ></i>
                                        </span>
                                    </template>
                                </span>
                      </el-tree>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CSDialog>


        <!-- 创建权限组 -->
        <CSDialog :dialog-visible="createOrEditModalVisible" dialog-title="添加权限组"
          @onConfirm="createOrEditRole" @onClose="createOrEditModalVisible = false" dialog-width="580px"
        >
          <template v-slot:dialog-content>
            <div class="form-panel">
              <div class="form-item">
                <div class="form-item-label">权限组名称</div>
                <div>
                  <input
                      type="text"
                      v-model="editRoleParams.name"
                      style="width:380px;"
                      placeholder="请输入"
                  />
                </div>
              </div>
            </div>
          </template>
        </CSDialog>

        <!-- 删除权限组 -->
        <CSDialog
            dialogTitle="提示" dialog-header-class=" "
            dialogWidth="580px" dialog-confirm-btn-text="确定"
            :dialogVisible="deleteRoleModalInfo.visible"
            @onClose="deleteRoleModalInfo.visible = false"
            @onConfirm="deleteRoleModalInfo.onOk"
            dialog-header-icon="icon-menua-zu13"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px; text-align: center;"
            >
                确定删除吗?
            </div>
        </CSDialog>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import { cloneDeep } from "lodash";
import {
    createRoleUrl,
    editRoleUrl,
    queryResourceUrl,
    deleteRoleUrl,
    queryRoleUrl,
    setupRoleResourcesUrl,
    queryRoleResourcesUrl,
} from "@/requestUrl";

export default {
    name: "roleManage",
    components: {
      CSDialog,
    },
    data() {
        return {
            queryParams: {
                name: "",
            },
            resourceList: [],
            // 正在进行绑定资源的权限组
            roleId: null,
            // 已经选中的资源
            checkedReousrceKey: [],
            // 展开已经绑定的资源
            checkedExpandedKey: [],
            // 权限组已经绑定的资源
            roleBindedResourceList: [],
            roleList: [],
            // 创建、修改权限组的参数
            editRoleParams: {
                name: "",
            },
            // 权限组权限设置
            roleAccessModalVisible: false,
            // 删除权限组弹窗信息
            deleteRoleModalInfo: {
                visible: false,
                roleId: "",
                onCancel: () => {
                    this.deleteRoleModalInfo.visible = false;
                },
                onOk: () => {
                    this.$fly
                        .post(deleteRoleUrl, {
                            id: this.deleteRoleModalInfo.roleId,
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("删除成功");
                            this.deleteRoleModalInfo.visible = false;
                            this.getRoleList();
                        });
                },
            },
            createOrEditModalVisible: false,
        };
    },
    mounted() {
        this.getRoleList();
    },
    created(){
        window.addEventListener("keydown",this.getRoleListDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getRoleListDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getRoleListDown);
    },
    methods: {
        getRoleListDown(e){
            if(e.keyCode == 13){
                this.getRoleList();
            }
        },
        /**
         * 添加资源
         * @param {Object} node 节点
         * @param {Object} data 当前节点的数据
         *  */
        appendResource(node, data) {
            if (this.checkedReousrceKey.includes(data.id)) {
                return;
            }
            if (node.level > 1) {
                const parentData = cloneDeep(node.parent.data);
                // 处理选中时 父级资源中唯一一个选中
                if (!this.checkedReousrceKey.includes(data.parentId)) {
                    this.checkedReousrceKey.push(data.parentId);
                    parentData.resources = [data];
                    this.roleBindedResourceList.push(parentData);
                } else {
                    // 处理选中时 父级资源中已经存在其他选中的子资源
                    const checkedChildren = parentData.resources.filter(
                        (child) => {
                            return this.checkedReousrceKey.includes(child.id);
                        }
                    );
                    this.roleBindedResourceList.forEach((resource) => {
                        if (resource.id == parentData.id) {
                            resource.resources = [...checkedChildren, data];
                        }
                        return resource;
                    });
                }
            } else {
                // 处理没有子资源的资源
                this.checkedReousrceKey.push(data.id);
                this.roleBindedResourceList.push(data);
            }
            this.checkedReousrceKey.push(data.id);
        },
        /**
         * 移除资源
         * @param {Object} node 节点
         * @param {Object} data 当前节点的数据
         *  */
        removeResource(node, data) {
            this.checkedReousrceKey.splice(
                this.checkedReousrceKey.indexOf(data.id),
                1
            );
            if (node.level > 1) {
                const parentData = cloneDeep(node.parent.data);
                parentData.resources = parentData.resources.filter((child) => {
                    return child.id != data.id;
                });
                if (parentData.resources.length == 0) {
                    this.checkedReousrceKey.splice(
                        this.checkedReousrceKey.indexOf(parentData.id),
                        1
                    );
                    this.roleBindedResourceList = this.roleBindedResourceList.filter(
                        (resource) => {
                            return resource.id != parentData.id;
                        }
                    );
                } else {
                    this.roleBindedResourceList = this.roleBindedResourceList.map(
                        (resource) => {
                            if (resource.id == parentData.id) {
                                resource = parentData;
                            }
                            return resource;
                        }
                    );
                }
            } else {
                this.roleBindedResourceList = this.roleBindedResourceList.filter(
                    (resource) => {
                        return resource.id != data.id;
                    }
                );
            }
        },
        // 更新权限组资源绑定
        setupRoleResources() {
            this.$fly
                .post(setupRoleResourcesUrl, {
                    roleId: this.roleId,
                    resources: this.checkedReousrceKey,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.roleBindedResourceList.currentCheck = [];
                    this.roleAccessModalVisible = false;
                });
        },
        /**
         * 获取权限组绑定的资源
         * @param {Number} roleId 权限组id
         *  */
        getRoleBindingResourceList(roleId) {
            this.roleId = roleId;
            return this.$fly
                .get(queryRoleResourcesUrl, { roleId })
                .then((res) => {
                    if (res.code !== 0) {
                        resolve(null);
                        return;
                    }
                    this.roleBindedResourceList = [];
                    this.checkedReousrceKey = [];
                    this.checkedExpandedKey = [];
                    res.data.forEach((val) => {
                        this.checkedReousrceKey.push(val.id);
                        if (
                            val.parentId !== 0 &&
                            !this.checkedExpandedKey.includes(val.parentId)
                        ) {
                            this.checkedExpandedKey.push(val.parentId);
                        }

                        this.roleBindedResourceList.push(val);
                    });
                    return res.data;
                });
        },
        // 打开权限组绑定资源弹窗
        openRoleBindingResourceDialog(roleId) {
            this.getRoleBindingResourceList(roleId);
            this.getResourceList();
            this.roleAccessModalVisible = true;
        },
        // 获取权限组列表
        getRoleList() {
            this.$fly.post(queryRoleUrl, this.queryParams).then((res) => {
                this.roleList = res.data;
            });
        },
        // 获取资源列表
        getResourceList() {
            this.$fly
                .post(queryResourceUrl, {
                    serviceId: "",
                    name: "",
                    menuItem: "",
                    path: "",
                    enable: true,
                    id: "",
                })
                .then((res) => {
                    this.resourceList = res.data;
                });
        },
        /**
         * 打开添加、修改权限组弹窗
         * @param {Object} role 权限组信息 如果不为null说明是编辑弹窗
         *  */
        openAddOrEditRoleModal(role) {
            if (role) {
                this.editRoleParams = {
                    name: role.name,
                    id: role.id,
                };
            } else {
                this.editRoleParams = {
                    name: "",
                };
            }
            this.createOrEditModalVisible = true;
        },
        // 创建或修改权限组
        createOrEditRole() {
            this.$fly
                .post(
                    this.editRoleParams.id ? editRoleUrl : createRoleUrl,
                    this.editRoleParams
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.createOrEditModalVisible = false;
                    this.getRoleList();
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
/deep/.el-tree-node__expand-icon
  font-size 24px
/deep/.el-tree-node__content
  min-height 26px
  height 36px
.form-panel
    padding 30px
    .form-item
        display flex
        flex-flow row nowrap
        align-items center
        font-size 24px
        &-label
            width 140px
            margin-right 20px
        input
            border 1px solid #979797
            padding 0 10px
            border-radius 4px
        &:not(:last-of-type)
            margin-bottom 20px
.custom-tree-node
    width 80%
    i
        float right
        color var(--pc-theme-color)
        &.gray
            color #999
</style>